<template>
  <div>
    <h1>New message</h1>

    <form @submit="submitForm" class="w-full lg:w-1/2 my-4 p-6 bg-white shadow-md">
      <div>
        <textarea v-model="message" class="h-48 block w-full px-3 py-2 text-black placeholder-gray-400 bg-white border border-gray-300 rounded shadow-sm" />
      </div>
      <div class="mt-6 p-3 text-sm bg-gray-100 text-gray-500 leading-6">
        <ul>
          <li><strong>{fullname}</strong> = Inserts recipients fullname</li>
          <li><strong>{username}</strong> = Inserts recipients username</li>
          <li><strong>{followers}</strong> = Inserts recipients count of followers</li>
          <li><strong>{following}</strong> = Inserts recipients count of following</li>
          <li><strong>{posts}</strong> = Inserts recipients count of posts (images)</li>
        </ul>
      </div>
      <div class="mt-6">
        <button class="pt-1 pr-3 pb-1 pl-3 h-10 text-white font-light tracking-wider bg-gray-500 rounded">
          Create message
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['projectId'],

  data() {
    return {
      message: null,
    }
  },
  methods: {
    submitForm(evt) {
      evt.preventDefault();

      axios.post(`${process.env.VUE_APP_API_URL}/projects/${this.projectId}/messages`, {
          text: this.message
        }).then(() => {
          this.$router.push(`/projects/${this.projectId}/messages?created=true`);
        }).catch(error => {
          // TODO: Handle errors
          console.log(error);
        });
    }
  }
}
</script>